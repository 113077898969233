<template>
  <TTView>
    <VRow>
      <VCol>
        <AccountCard
          :account="account"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { DEFAULT_ACCOUNT } from '../../../components/accounts/accounts/common';
import AccountCard from '../../../components/accounts/accounts/AccountCard.vue';

export default {
  name: 'Account',

  components: {
    AccountCard,
  },

  data() {
    return {
      loading: false,
      account: { ...DEFAULT_ACCOUNT },
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const { accountId: id } = this.$route.params;
        const data = { id };
        const accountResponse = await this.$di.api.Account.getAccount(data);

        this.account = accountResponse.account;
      } catch (err) {
        this.$di.notify.errorHandler(err);
        this.$di.redirect.errorHandler404(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
